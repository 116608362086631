<template>
  <div>
    <b-row v-if="!isLoading">
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-books small-gap-right" />
            <h4 class="text-primary mb-0">
              Bank Materi
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  v-model="pageLength"
                  class="w-auto mr-1"
                  :options="options"
                />
              </b-col>
              <b-col />
              <b-col>
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Filter</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1 search-min-width"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'title', type: 'asc' },
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Nama Materi -->
                <span v-if="props.column.field === 'title'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.title }}</span>
                </span>

                <!-- Column: Kelas -->
                <span
                  v-else-if="props.column.field === 'classroom'"
                  class="d-flex justify-content-start"
                >
                  <b-badge variant="success" class="small-gap-right text-white">
                    {{ props.row.classroom }}
                  </b-badge>
                </span>

                <!-- Column: Download -->
                <span
                  v-else-if="props.column.field === 'download'"
                  class="d-flex justify-content-start"
                >
                  <b-button
                    v-if="props.row.download.file"
                    variant="success"
                    size="sm"
                    class="mr-1"
                    :href="props.row.download.file"
                    target="_blank"
                  >
                    <i class="ti ti-download" />
                  </b-button>

                  <b-button
                    v-else-if="props.row.download.video_url"
                    variant="info"
                    size="sm"
                    class="mr-1"
                    :href="props.row.download.video_url"
                    target="_blank"
                  >
                    <i class="ti ti-video" />
                  </b-button>

                  <b-button
                    v-else
                    variant="warning"
                    size="sm"
                    class="mr-1"
                    :href="props.row.download.url"
                    target="_blank"
                  >
                    <i class="ti ti-link" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Loading State -->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Data Materi
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// *Utils
import { pascalCase, avatar } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'
import client from '@/libs/http/axios-config'

// *Component
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,

    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // State
      isLoading: true,
      buttonLoadingId: null,

      // Filter
      selectedClassroom: 'Semua Kelas',
      classroomList: [],

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 10,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Materi',
          field: 'title',
          width: '30%',
        },
        {
          label: 'Sekolah',
          field: 'school',
        },
        {
          label: 'Kelas',
          field: 'classroom',
        },
        {
          label: 'Aksi',
          field: 'download',
        },
      ],
      rows: [],
    }
  },
  async mounted() {
    await client
      .get('/admin/content-materials')
      .then((response) => {
        const materials = response.data.data

        const listOfmaterials = materials.map((material) => {
          null
          return {
            id: material.id,
            title: material.title,
            school: material.class_content?.classroom?.school?.name ?? '-',
            classroom: material.class_content?.classroom?.name ?? '-',
            download: {
              file: material.file,
              video_url: material.video_url,
              url: material.url,
            },
          }
        })

        this.rows = listOfmaterials
        this.isLoading = false
      })
      .catch((error) => {
        console.log(error)

        this.showToast(
          'error',
          'XIcon',
          'Gagal',
          'Data Gagal Dimuat, Silahkan Coba Lagi'
        )
      })
  },
  methods: {
    pascalCase,
    checkAuthorizeRole,
    showImportModal() {
      this.$bvModal.show('student-import-modal')
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    // async handleExport() {
    //   this.isButtonExportLoading = true
    //   await client
    //     .get('school-admin/users-download?type=Student', {
    //       responseType: 'blob',
    //     })
    //     .then((response) => {
    //       this.isButtonExportLoading = false

    //       const blob = new Blob([response.data], { type: 'application/xlsx' })
    //       const link = document.createElement('a')
    //       link.href = URL.createObjectURL(blob)
    //       link.download = 'Data Siswa.xlsx'
    //       link.click()
    //       URL.revokeObjectURL(link.href)
    //     })
    //     .catch((error) => {
    //       this.isButtonExportLoading = false
    //       this.showToast('error', 'XIcon', 'Gagal', error.response.data.message)
    //     })
    // },
    // downloadData() {
    //   const data = XLSX.utils.json_to_sheet(this.rows)
    //   const wb = XLSX.utils.book_new()
    //   XLSX.utils.book_append_sheet(wb, data, 'data')
    //   XLSX.writeFile(wb, 'data_siswa.xlsx')
    // },

    // Showing sweetalert when delete user
    handleDelete(user) {
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Data yang dihapus tidak dapat dikembalikan',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.buttonLoadingId = user.id
          this.deleteStudent(user.id)
        }
      })
    },

    // Delete student endpoint
    async deleteStudent(studentId) {
      const data = new FormData()
      data.append('_method', 'PUT')
      await client.post(`/admin/users/${studentId}`, data).then(() => {
        this.refreshTableData().then(() => {
          this.buttonLoadingId = ''
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Siswa berhasil dihapus'
          )
        })
      })
    },

    // Refresh data
    async refreshTableData() {
      // this.isLoading = true

      const filterbyClassroom =
        this.selectedClassroom === 'Semua Kelas' ? '' : this.selectedClassroom

      await client
        .get(
          `/admin/users?role=Student&status=Active&per_page=9999999&classroom_id=${filterbyClassroom}`
        )
        .then((response) => {
          const students = response.data.data.data

          const listOfStudents = students.map((student) => {
            return {
              id: student.id,
              fullname: pascalCase(student.name),
              avatar: student.avatar,
              phone: student.phone,
              classroom: student.school.name,
              email: student.email,
            }
          })

          this.rows = listOfStudents
          // this.isLoading = false
        })
        .catch((error) => {
          console.log(error)

          this.showToast(
            'error',
            'XIcon',
            'Gagal',
            'Data Gagal Dimuat, Silahkan Coba Lagi'
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}
</style>
